import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiLogoGit } from 'react-icons/bi';
import { FaDatabase, FaLinux, FaNodeJs, FaVuejs } from 'react-icons/fa';
import {
  SiAmazonaws,
  SiApache,
  SiBootstrap,
  SiCelery,
  SiCss3,
  SiDocker,
  SiExpress,
  SiFastify,
  SiFlask,
  SiGraphql,
  SiHtml5,
  SiJavascript,
  SiKubernetes,
  SiLaravel,
  SiNestjs,
  SiNginx,
  SiNumpy,
  SiNuxtdotjs,
  SiPhp,
  SiPycharm,
  SiPython,
  SiReact,
  SiSass,
  SiScikitlearn,
  SiStrapi,
  SiTailwindcss,
  SiTypescript,
} from 'react-icons/si';
import { TbBrandVscode } from 'react-icons/tb';
import { useSearchParams } from 'react-router-dom';
import CVFooter from '../../CVFooter/CVFooter';
import { getHueRotation } from './../../../utils/hue-rotation';

const AlphaSection: React.FC = () => {
  const [searchParams] = useSearchParams();
  const hueRotation = getHueRotation(searchParams);
  const { t } = useTranslation();

  const icons = [
    { icon: <SiJavascript fill='#4AF626' />, name: 'JavaScript' },
    { icon: <SiTypescript fill='#4AF626' />, name: 'TypeScript' },
    { icon: <FaNodeJs fill='#4AF626' />, name: 'NodeJS' },
    { icon: <SiPython fill='#4AF626' />, name: 'Python' },
    { icon: <SiPhp fill='#4AF626' />, name: 'PHP' },
    { icon: <SiHtml5 fill='#4AF626' />, name: 'HTML5' },
    { icon: <SiCss3 fill='#4AF626' />, name: 'CSS3' },
    { icon: <SiDocker fill='#4AF626' />, name: 'Docker' },
    { icon: <SiKubernetes fill='#4AF626' />, name: 'Kubernetes' },
    { icon: <SiNginx fill='#4AF626' />, name: 'Nginx' },
    { icon: <SiApache fill='#4AF626' />, name: 'Apache' },
    { icon: <BiLogoGit fill='#4AF626' />, name: 'Git' },
    { icon: <FaDatabase fill='#4AF626' />, name: 'SQL' },
    { icon: <SiGraphql fill='#4AF626' />, name: 'GraphQL' },
    { icon: <SiLaravel fill='#4AF626' />, name: 'Laravel' },
    { icon: <SiFlask fill='#4AF626' />, name: 'Flask' },
    { icon: <SiExpress fill='#4AF626' />, name: 'Express' },
    { icon: <SiNestjs fill='#4AF626' />, name: 'NestJS' },
    { icon: <SiFastify fill='#4AF626' />, name: 'Fastify' },
    { icon: <FaVuejs fill='#4AF626' />, name: 'VueJS' },
    { icon: <SiNuxtdotjs fill='#4AF626' />, name: 'NuxtJS' },
    { icon: <SiReact fill='#4AF626' />, name: 'React' },
    { icon: <SiTailwindcss fill='#4AF626' />, name: 'TailwindCSS' },
    { icon: <SiBootstrap fill='#4AF626' />, name: 'Bootstrap' },
    { icon: <SiSass fill='#4AF626' />, name: 'Sass' },
    { icon: <SiCelery fill='#4AF626' />, name: 'Celery' },
    { icon: <SiScikitlearn fill='#4AF626' />, name: 'Scikit-Learn' },
    { icon: <SiNumpy fill='#4AF626' />, name: 'NumPy' },
    { icon: <SiAmazonaws fill='#4AF626' />, name: 'AWS' },
    { icon: <FaLinux fill='#4AF626' />, name: 'Linux' },
    { icon: <SiPycharm fill='#4AF626' />, name: 'PyCharm' },
    { icon: <TbBrandVscode stroke='#4AF626' />, name: 'VSCode' },
    { icon: <SiStrapi fill='#4AF626' />, name: 'Strapi' },
  ];

  return (
    <section>
      <div className='cv-main'>
        <article>
          <div className='cv-picture'>
            <picture>
              <source
                media='(max-width: 700px)'
                srcSet='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/xs.webp'
                type='image/webp'
              />
              <source
                media='(max-width: 850px)'
                srcSet='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/md.webp'
                type='image/webp'
              />
              <source
                media='(max-width: 2200px)'
                srcSet='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/lg.webp'
                type='image/webp'
              />
              <source
                media='(max-width: 5000px)'
                srcSet='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/xl.webp'
                type='image/webp'
              />
              <img
                src='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/lg.webp'
                sizes='(max-width: 700px) 100vw, (max-width: 850px) 50vw, (max-width: 2200px) calc(100vw / 3), 50vw'
                width='300'
                height='300'
                alt='Lorenzo Rottigni Profile'
                itemProp='image'
                title='Lorenzo Rottigni'
                itemType='https://schema.org/ImageObject'
                itemScope
                style={{ filter: `hue-rotate(-${hueRotation})` }}
              />
            </picture>
          </div>
          <div className='content mt-2'>
            <h3>{t('cv.work-experience.title')}</h3>
            <h4>
              {t('cv.work-experience.deesup.title')}
              <span>{t('cv.work-experience.deesup.date')}</span>
            </h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.work-experience.deesup.text-content.0'),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.work-experience.deesup.text-content.1'),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.work-experience.deesup.text-content.2'),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.work-experience.deesup.text-content.3'),
              }}
            />
            <ul>
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.0'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.1'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.2'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.3'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.4'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.5'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.6'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.7'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.8'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.9'),
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.10'),
                }}
              />
              {/*<li
                dangerouslySetInnerHTML={{
                  __html: t('cv.work-experience.deesup.list.11'),
                }}
              /> */}
            </ul>
          </div>
        </article>
        <article>
          <div className='cv-picture'>
            <picture>
              <source
                media='(max-width: 700px)'
                srcSet='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/xs.webp'
                type='image/webp'
              />
              <source
                media='(max-width: 850px)'
                srcSet='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/md.webp'
                type='image/webp'
              />
              <source
                media='(max-width: 2200px)'
                srcSet='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/lg.webp'
                type='image/webp'
              />
              <source
                media='(max-width: 5000px)'
                srcSet='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/xl.webp'
                type='image/webp'
              />
              <img
                src='https://s3.rottigni.tech/portfolio.rottigni.tech/static/images/hero_profile/lg.webp'
                sizes='(max-width: 700px) 100vw, (max-width: 850px) 50vw, (max-width: 2200px) calc(100vw / 3), 50vw'
                width='300'
                height='300'
                alt='Lorenzo Rottigni Profile'
                itemProp='image'
                title='Lorenzo Rottigni'
                itemType='https://schema.org/ImageObject'
                itemScope
                style={{ filter: `hue-rotate(-${hueRotation})` }}
              />
            </picture>
          </div>
          <div className='content'>
            <h1>{t('cv.intro.title')}</h1>
            <h2>{t('cv.intro.subtitle')}</h2>
            <p>{t('cv.intro.text-content.0')}</p>
            <p>{t('cv.intro.text-content.1')}</p>
            <h3>{t('cv.my-work.title')}</h3>
            <p>{t('cv.my-work.text-content.0')}</p>
            <p>{t('cv.my-work.text-content.1')}</p>
            <h4>{t('cv.my-work.front-end.title')}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.my-work.front-end.text-content'),
              }}
            />
            <h4>{t('cv.my-work.back-end.title')}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.my-work.back-end.text-content'),
              }}
            />
            <h4>{t('cv.my-work.dev-ops.title')}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.my-work.dev-ops.text-content'),
              }}
            />
            <h4>{t('cv.my-work.networking.title')}</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.my-work.networking.text-content.0'),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('cv.my-work.networking.text-content.1'),
              }}
            />
            <h3>{t('cv.my-tools.title')}</h3>
            <ul className='icons'>
              {icons.map((item, index) => (
                <li key={index}>
                  <span>{item.icon}</span>
                </li>
              ))}
            </ul>
          </div>
        </article>
        <div className='pagination'>
          <span>1</span>/2
        </div>
      </div>
      <CVFooter />
    </section>
  );
};

export default AlphaSection;
